<template>
  <a-card class="login-form-card">
    <ValidationObserver ref="observer">
      <a-form
        :label-width="80"
        slot-scope="{ validate }"
        @submit.prevent="validate().then(handleSubmit)"
      >
        <h2 class="title">
          {{ labels.subtitle }}
          <b :style="{ display: 'block' }">{{ labels.appName }}</b>
        </h2>
        <Input
          v-model.trim="form.email"
          rules="required"
          vid="email"
          :placeholder="labels.form.email.placeholder"
          size="large"
          :allowClear="true"
          :label="labels.form.email.label"
          :autoFocus="true"
          @input="onlyLowecaseValidEmail($event, 'email')"
        />
        <Input
          v-model="form.password"
          type="password"
          rules="required"
          vid="subject"
          :placeholder="labels.form.password.placeholder"
          size="large"
          :allowClear="true"
          :label="labels.form.password.label"
          autocomplete="current-password"
        />

        <RecaptchaCheckbox
          v-if="false"
          v-model="form.recaptchaToken"
          vid="recaptcha"
          :label="'recaptcha'"
          :ref="'recaptcha'"
        />

        <a-form-item style="text-align: right">
          <router-link
            :to="{ name: 'password-reset' }"
            class="forget-password-link"
          >
            {{ labels.form.forgotPassword }}
          </router-link>
        </a-form-item>
        <a-form-item style="text-align: right">
          <a-button
            type="default"
            html-type="submit"
            :loading="submitting"
            :disabled="submitting"
            size="large"
            class="btn-login"
            @click.prevent="validate().then(handleSubmit)"
          >
            <span>{{ labels.form.submit }}</span>
          </a-button>
        </a-form-item>
        <a-form-item style="text-align: center">
          <span class="label">{{ labels.form.newInApp(labels.appName) }}</span>
          <router-link :to="{ name: 'register' }" class="register-link">
            {{ labels.form.register }}
          </router-link>
        </a-form-item>
      </a-form>
    </ValidationObserver>
  </a-card>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import _ from "lodash";
import Input from "@/components/core/VeeValidateForm/Input";
import RecaptchaCheckbox from "@/components/core/VeeValidateForm/RecaptchaCheckbox";
import labels from "@/utils/labels";
import EventBus, { ListEvents } from "../event";
import { mapActions, mapState } from "vuex";

const initialState = {
  email: "",
  password: "",
  // recaptchaToken: null,
};
export default {
  name: "LoginForm",
  components: {
    ValidationObserver,
    Input,
    RecaptchaCheckbox,
  },
  data() {
    return {
      form: _.cloneDeep(initialState),
      submitting: false,
      labels: {
        ...labels.loginForm,
        appName: labels.appName,
        unknownServerError: labels.unknownServerError,
      },
    };
  },
  methods: {
    ...mapActions("clientAuth", ["handleLogin"]),
    async onlyLowecaseValidEmail(value = "", field) {
      this.form[field] = await value
        .replace(/[^a-zA-Z0-9_\-@.]/g, "")
        .replaceAll(" ", "")
        .toLowerCase();
    },
    async handleSubmit() {
      try {
        // if (!!this.form.recaptchaToken === false) {
        //   this.$error({
        //     title: this.labels.error,
        //     content: this.labels.form.notRobot,
        //     centered: true,
        //   });
        //   return;
        // }
        this.submitting = true;

        const formData = new FormData();
        const data = _.cloneDeep(this.form);
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });

        let { firstname, lastname } = await this.handleLogin(formData);
        const redirectRoute = this.redirectRoute;
        this.$router.push({ name: redirectRoute || "home" });
        this.$store.state.redirectRoute = null;
        this.$message.success(
          `${this.labels.form.success}, ${firstname || ""} ${lastname || ""}`
        );
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        if (error?.response?.status === 401) {
          this.$error({
            title: this.labels.form.error,
            content:
              error?.response?.data?.message ||
              error?.message ||
              this.labels.form.errorMessage,
            centered: true,
          });
        } else {
          this.$notification["error"]({
            message: `${this.labels.form.error} ${error.response.code}`,
            description: this.labels.unknownServerError,
          });
        }
        EventBus.$emit(ListEvents.reset_recaptcha);
      } finally {
        this.submitting = false;
      }
    },
  },
  computed: {
    ...mapState(["redirectRoute"]),
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.login-form-card {
  background-color: color(c-secondary);
  border-color: color(c-hover-light);
  box-shadow: 0 0 15px rgba(55, 245, 249, 35%);
  animation: shadowGlow 2s infinite ease;
  padding: 3.125rem;
  border-radius: 10px;
  @media screen and (max-width: $screen-mobile) {
    padding: 2.125rem 1rem;
  }
  .title {
    font-family: "Exo 2", sans-serif;
    color: color(--white);
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
    word-break: break-word;
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .ant-card-body {
    padding: 0;
  }
  .ant-form label {
    font-size: 0.875rem;
    color: color(--white);
    font-family: "Roboto", sans-serif;
  }
  .ant-input {
    background-color: color(c-primary);
    height: 3.75rem;
    color: color(--white);
    &:active,
    &:focus,
    &:hover {
      border-color: color(c-hover);
    }
  }
  .ant-input-affix-wrapper {
    .ant-input:not(:first-child) {
      padding-left: 2.8125rem;
    }
    .ant-input-prefix {
      color: color(--white);
      font-size: 1.375rem;
      font-family: "Roboto", sans-serif;
    }
    .ant-input-clear-icon,
    .ant-input-password-icon,
    .ant-input-textarea-clear-icon {
      color: color(--white);
    }
  }

  .has-error .ant-input-affix-wrapper .ant-input,
  .has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: color(c-primary);
  }

  .btn-login {
    color: color(c-primary) !important;
    background-color: color(c-hover);
    border-color: color(c-hover);
    height: 51px;
    padding: 0 1.75rem;
    width: 100% !important;
  }

  .label {
    color: color(--white);
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    margin-left: 5px;
  }
  .register-link {
    @extend .label;
    color: color(c-hover);
  }
  .forget-password-link {
    @extend .label;
    &:hover {
      color: color(c-hover);
    }
  }
}

input:-internal-autofill-selected {
  background-color: color(c-primary) !important;
  border-color: color(c-hover) !important;
  color: black !important;
}

// animation
@keyframes shadowGlow {
  0% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.15);
  }
  25% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.25);
  }
  50% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.35);
  }
  50% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.45);
  }
  100% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.15);
  }
}
</style>
