<template>
  <ValidationProvider
    v-if="sitekey"
    :vid="vid"
    :name="$attrs.label"
    :rules="rules"
  >
    <a-form-item
      slot-scope="{ errors, flags }"
      :align="$attrs.align || 'left'"
      :validateStatus="resolveState({ errors, flags })"
      :help="errors[0]"
      :style="{ textAlign: 'right', display: 'flex', justifyContent: 'center' }"
      class="ant-form-item-captcha"
    >
      <vue-recaptcha
        ref="recaptcha"
        :sitekey="sitekey"
        language="es-DO"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired"
        :size="size"
        class="w-100"
      />
    </a-form-item>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { VueRecaptcha } from "vue-recaptcha";
import EventBus, { ListEvents } from "../../../event";

const sitekey = process.env.VUE_APP_RECAPTCHA_SITE_KEY || "";

export default {
  components: {
    ValidationProvider,
    VueRecaptcha,
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String, Array],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
  },
  data: () => ({
    innerValue: "",
    sitekey: sitekey,
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
      if (this.$attrs.onChange && typeof this.$attrs.onChange === "function") {
        this.$attrs.onChange(newVal);
      }
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  computed: {
    size() {
      return this.$store.state.layout?.screenWidth < 400 ? "compact" : "normal";
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
    EventBus.$on(ListEvents.reset_recaptcha, this.onCaptchaExpired);
  },
  methods: {
    resolveState({ errors, flags }) {
      if (errors[0]) {
        return "error";
      }

      if (flags.pending) {
        return "validating";
      }

      if (flags.valid) {
        return "success";
      }

      return "";
    },
    onCaptchaVerified(recaptchaToken) {
      this.innerValue = recaptchaToken;
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
      this.innerValue = "";
    },
  },
  beforeDestroy() {
    EventBus.$off(ListEvents.reset_recaptcha, this.onCaptchaExpired);
  },
};
</script>
<style lang="scss">
.ant-form-item-captcha .ant-form-item-children {
  div {
    div {
      width: 100% !important;
      div {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
