<template>
  <a-layout class="login">
    <TitleBreadcrumb />
    <div class="content-base container">
      <a-row type="flex" justify="center" :gutter="[16, 16]">
        <a-col :xs="24" :sm="24" :md="12" :lg="12">
          <LoginForm />
        </a-col>
      </a-row>
    </div>
  </a-layout>
</template>
<script>
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb.vue";
import LoginForm from "@/components/LoginForm.vue";
import store from "@/store";
import loadingMixin from "@/mixins/loadingMixin";
import { pageTitle } from "../../utils/utils";
import labels from "@/utils/labels";
export default {
  components: { TitleBreadcrumb, LoginForm },
  name: "Login",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.loginForm.text),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.loginForm.text,
      },
    ],
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state?.clientAuth?.authenticated) next();
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.login {
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  max-width: 100%;
  .content-base {
    padding-top: 60px;
    @media screen and (max-width: $screen-mobile) {
      padding-top: 50px;
      max-width: 100% !important;
    }
  }
}
</style>
